<template>
  <div class="edit-profile">
    <edit-rejected-profile v-if="getUser?.status === 'Rejected' || getUser?.role === null"/>
    <edit-profile v-else />
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import EditProfile from "@/views/profile/EditProfile/EditProfile.vue";
import EditRejectedProfile from "@/views/profile/EditProfile/EditRejectedProfile.vue";
export default {
  name: "index",
  components: {EditRejectedProfile, EditProfile},

  computed: {
    ...mapGetters([
      'getUser',
    ])
  },
}
</script>

<style lang="scss" scoped>

</style>
